<template>
    <KinesisContainer >
        <KinesisElement :strength="resolucao < 700 ? 0 : 15" :type="'depth'">
          <div @mouseup="changeState" v-if="true" class="text-center">
              <h1 class="title">{{ title }}</h1>
              <h4 class="subtitle mt-5">{{ subtitle }}</h4>              
              <p class="text  mt-5" v-html="text"></p>
          </div>  
          <img @mouseup="changeState" v-if="false" class='mobile-img' style="width: 200px" :src="imageMobile">
          <img @mouseup="changeState" v-if="false" class='desktop' style="width: 80%" :src="imageDesktop">
        </KinesisElement>
    </KinesisContainer>
</template>

<script>
import { KinesisContainer, KinesisElement } from 'vue-kinesis'

export default {
  name: "SliderElement",
  props: {
   text: {type: String, default: ""},
   title: {type: String, default: ""},
   subtitle: {type: String, default: ""},
   imageDesktop: {type: String, default: ""},
   imageMobile: {type: String, default: ""}  
  },
  data() {
    return {
        showText: true
    };
  },
  methods: {
      changeState()
      {
        this.showText = !this.showText;
      }
  },
  computed: {
    resolucao(){
      return window.innerWidth;
    }
  },
  components: {
    KinesisContainer, 
    KinesisElement
  },
};
</script>


<style scoped>
.title{
  color : #f54308;
}
.subtitle{
  text-transform: uppercase;
  color : #f54308;
}
.text{
  padding-left: 10%;
  padding-right: 10%;
}

.desktop{
    display: none;
    visibility: hidden;
  }
.mobile-img{
  display: unset;
  visibility: unset;
}

@media(min-width: 700px){
  .desktop{
    display: unset;
    visibility: unset;
  }
  .mobile-img{
    visibility: hidden;
    display: none;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>