<template>
  <div>
    <TopMenu />
    <div style="margin-top: 20vh;">  
      <carousel class="col-12 text-center" :paginationSize="20" :paginationColor="'#ffffff22'" :paginationActiveColor="'#f44c09'" :autoplay="true" :autoplayTimeout="5000" :perPage="1" :paginationEnabled="true">
        <!-- Nossos trabalhos -->
        <slide>
          <SliderElement title="NOSSOS TRABALHOS" subtitle="" text="<p>A MG Cobrança atende a todos os processos de recuperação de crédito no âmbito de cobrança corretiva, com ênfase no sucesso do resultado Final.</p>
          <p>Nós cuidamos da sua inadimplência e aliamos á nossa prática a sua necessidade. É um conjunto de validações para o cumprimento  de obrigação acordada. O importante na recupertação de crédito é a flexibilidade no recebimento, a manutenção na carteira vigente e o respeito e empatia a quem abordamos.</p>
          <p>Contamos com diversas ferramentas para a consolidação do nosso trabalho:</p>
          <p>- Sistema online para PARAMETRIZAÇÃO com Credor</p>
          <p>- Sistema de busca</p>
          <p>- Whatsapp em Massa</p>
          <p>- Assinatura digital</p>
          <p>- Aplicativos eletrônicos de validação de Acordo</p>
          " imageMobile='https://mg-cobrancas.s3.us-west-2.amazonaws.com/imagens/nossos_processos_mobile.jpg' imageDesktop="https://mg-cobrancas.s3.us-west-2.amazonaws.com/imagens/nossos_processos.jpg"/>
        </slide>

        <slide>
          <!-- Proposito -->
          <SliderElement title="O PROPOSITO" subtitle="Os nossos maiores recursos são os HUMANOS!" text="<p>Nosso propósito é atender a necessidade do cliente diante da evolução do mercado. Com a misssão de agregar valores estratégicos e engajados para um resultado de alta qualidade e desempenho em seus serviços, formamos uma escada de valores de alto padrão.</p>
          <p>A MG Cobrança tem como objetivo atingir clientes potenciais em sua métrica de planejamento.</p>
          " imageMobile='https://mg-cobrancas.s3.us-west-2.amazonaws.com/imagens/o_proposito_mobile.jpg' imageDesktop="https://mg-cobrancas.s3.us-west-2.amazonaws.com/imagens/o_proposito.jpg"/>
        </slide>

        <slide>
          <!-- Cobranças amigavéis -->
          <SliderElement title="COBRANÇAS AMIGÁVEIS" subtitle="" text="<p>
          Essas Cobranças, apesar de sua simplicidade, exige ser realizada por profissionais qualificados. No entanto, há inúmeras formas de fazer 
          cobrança mas todas com a exigibilidade de conduta, ética, processos pré 
          determinados e muito respeito.</p>
          <p>Apresentamos resultados em curto prazo de tempo, recapitalizando a sua empresa e alavancando a nossa com sua satisfação.</p>
          " imageMobile='https://mg-cobrancas.s3.us-west-2.amazonaws.com/imagens/cobran%C3%A7as_amigaveis_mobile.jpg' imageDesktop="https://mg-cobrancas.s3.us-west-2.amazonaws.com/imagens/cobran%C3%A7as_amigaveis.jpg"/>
        </slide>

        <slide>
          <!-- Assessoria Juridica -->
          <SliderElement title="ASSESSORIA JURÍDICA" subtitle="" text="<p>Dentro do contexto da cobrança é realizada quando necessária ações judiciais. Todas são feitas com prévio aviso e autorização do credor, acompanhadas pela MG Cobrança desde o momento em que é protocolada até a finalização do processo.</p><p>Todas essas cobranças, são realizadas no âmbito judicial com profissionais capacitados, orientando assim a melhor forma de conduzi-las. A nossa empresa possui em seu corporativo parcerias com advogados que realizam e terceirizam esse trabalho.</p" imageMobile='https://mg-cobrancas.s3.us-west-2.amazonaws.com/imagens/assessoria_jur_mobile.jpg' imageDesktop="https://mg-cobrancas.s3.us-west-2.amazonaws.com/imagens/assessoria_jur.jpg"/>
        </slide>





      </carousel>
    </div>
    <!-- Tópicos -->

  </div>
</template>

<script>
import TopMenu from "../menu/topMenu.vue";
import { Carousel, Slide } from 'vue-carousel';
import SliderElement from "../SliderElement";

export default {
  name: "Trabalhos",
  data() {
    return {};
  },
  components: {
    TopMenu,
    Carousel,
    Slide, 
    SliderElement
  },
};
</script>


<style scoped>
h1,h4{
  text-transform: uppercase;
}
.tracking-in-contract-bck {
  -webkit-animation: tracking-in-contract-bck 3s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck 3s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}
.div-row {
  display: flex;
  flex-direction: row;
}

@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.photo{
  width: 60%;
  height: 100%;
  background-size: cover;
}
</style>