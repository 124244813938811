<template >
  <div>
    <Trabalhos />
  </div>
</template>

<script>
import Trabalhos from "../components/trabalhos/trabalhos.vue";
export default {
  components: {
    Trabalhos,
  },
};
</script>
<style scoped>
</style>